import { BigNumber } from "ethers"
import { BigNumberish } from "@ethersproject/bignumber"
import { formatUnits, parseUnits } from "@ethersproject/units"
import { ArbitrumChainIds, PolygonChainIds } from "./network"

export function toTrunc(value: any, digits: number = 4) {
    if (!value) {
        return value
    }

    const regex = new RegExp("^-?\\d+(?:.\\d{0," + (digits || -1) + "})?")

    return value.toString().match(regex)[0]
}

export function formatNumber(number: number) {
    if (!number) {
        return number
    }

    const num_parts = number.toString().split(".")
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    return num_parts.join(".")
}

export function formatEtherNumber(chainId: number, tokenName: string, value: any) {
    if (!value) {
        return 0
    }

    return formatNumber(toTrunc(formatEther(chainId, tokenName, value), 4))
}

export function getPercentage(number1: number, number2: number) {
    if (number1 === 0) {
        return 0
    }

    if (number2 == 0) {
        return 100
    }

    return (number1 / number2) * 100
}

export function getRating(number1: number, number2: number) {
    if (number1 === 0) {
        return 0
    }

    if (number2 == 0) {
        return 100
    }

    return (number1 / (number1 + number2)) * 100
}

export function formatCurrency(value: number) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    return formatter.format(value)
}

export const parseBalance = (value: BigNumberish, decimals = 18, decimalsToDisplay = 3) =>
    parseFloat(formatUnits(value, decimals)).toFixed(decimalsToDisplay)

export const formatEther = (chainId: number, tokenName: string, value: BigNumberish) => {
    if (!value) {
        return value
    }

    let unitNumbers = 18
    if (PolygonChainIds.indexOf(chainId) >= 0 || ArbitrumChainIds.indexOf(chainId) >= 0) {
        if (["USDT", "USDC"].indexOf(tokenName) >= 0) {
            unitNumbers = 6
        }
    }

    return +formatUnits(value, unitNumbers)
}

export const parseEther = (chainId: number, tokenName: string, value: string): BigNumber => {
    if (!value) {
        return BigNumber.from(value)
    }

    let unitNumbers = 18
    if (PolygonChainIds.indexOf(chainId) >= 0 || ArbitrumChainIds.indexOf(chainId) >= 0) {
        if (["USDT", "USDC"].indexOf(tokenName) >= 0) {
            unitNumbers = 6
        }
    }
    return parseUnits(value.toString(), unitNumbers)
}

export function nFormatter(num, digits) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value
        })

    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0"
}
